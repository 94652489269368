@use '../styles/Typography.module.scss';


.text-input {
    display: block;
    width: 100%;
    cursor: text;
    background-color: var(--layer-1_bg);
    color: var(--text);
    padding: 1rem;
    z-index: 1;
    outline: 2px solid transparent;
    border-bottom: 1px solid var(--accent-1);
    transition: 0.2s linear border, 0.2s linear outline;
    &:is(textarea){
        min-height: 8rem;
    }
    &::placeholder {
        color: var(--text_placeholder);
    }
    &:focus {   
        outline: 2px solid var(--interactive_border);
        border-bottom: 1px solid transparent;
    }
    &:disabled {
        color: var(--text_disabled);
        &::placeholder {
            color: var(--text_disabled);
        }
        cursor: not-allowed;
        border-bottom: 1px solid transparent;
    }
}