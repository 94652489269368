.fade-in-container {
    position: relative;
    .fade-in-content {
        transition: 0.75s ease-out opacity, 0.4s ease-out top;
        position: relative;
        top: -1rem;
        opacity: 0;
    }
    &.no-top {
        .fade-in-content {
            transition: 0.75s ease-out opacity;
            top: 0;
        }
    }
    &.active {
        .fade-in-content {
            top: 0;
            opacity: 1;
        }
    }
}



